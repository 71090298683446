(function ($, window, document, undefined) {
  'use strict';

  $(function () {
    $K.init();
  });

  $('a[href*="#"]').on('click', function(e) {
    e.preventDefault()

    $('html, body').animate(
      {
        scrollTop: $($(this).attr('href')).offset().top-150,
      },
      500,
      'swing'
    )
  });

})(jQuery, window, document);

(function($K)
{
    $K.add('module', 'aboutModule', {
        init: function(app, context)
        {
            this.app = app;

            // define service
            this.modal = app.modal;
            this.context = context;
            this.$element = context.getElement();

            this._build();
        },
        _build: function() {
          this.$element.addClass('load-modal');
        },
        onclick: function(e, element, type)
        {
          if (type === 'show')
          {
              this.showModal();
          }
        },
        showModal: function()
        {
            this.modal.open({
                target: '#local-order-form',
                title: 'Local Order Inquiry',
                width: '50%',
                height: '360px'
            });
        }
    });
})(Kube);